/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import { cleanProps, getYearsArray } from '@/utils'
import { parse } from "query-string"
import SEO from '@/components/Seo'
import { useNavigate, useLocation } from "@reach/router"
import { OneColumnBlock } from '@/components/Column'
import ContactForm from '@/components/global/ContactForm'
import IconWrapper from '@/utils/IconWrapper'
import NewsThumb from '@/components/NewsThumb'
import useConstant from '@/hooks/useConstant'
import useNews from '@/hooks/useNews'
import Button from '@/components/global/Button'
import DownArrow from '@/icons/down-arrow.svg'
import SimpleSlider from '@/components/SimpleSlider'
import TextImageCtaBlock from '@/components/TextImageCtaBlock'
import styles from './NewsPost.module.css'

function formatPosts(posts, images) {
    return posts
        .map(({ featured_image_url, date, acf, ...entry }) => ({
            ...entry,
            date: new Date(Date.parse(date)).toLocaleString("en-gb", { year:"numeric", month:"short", day:"numeric" }),
            image: acf.thumbnail_image || (featured_image_url && images[featured_image_url.wordpress_id]),
            category: entry.categories.length ? entry.categories[0].name : ''
        }))
}

function filterPosts(posts, params) {
    return posts
        .filter(({categories}) => {
            const slugs = categories.map(cat => cat.name.toLowerCase().replace(' ', '-'))
            return !params.cats.length || !!params.cats.find(p => slugs.includes(p))
        })
        .filter(({date}) => {
            return !params.dates.length || params.dates.find(d => date.indexOf(d) > -1)
        })
        .filter(({topics}) => {
            return !params.topics.length || !!params.topics.find(p => topics.includes(+p))
        })
}

function News({ 
    pageContext: { entries, topics, media, categories },
	data: {
		featuredPosts: {
			options: {
				featured_posts: featuredPosts
			}
		}
	}
}) {
	const { blocks, yoast, featured_media } = useNews()
	const data = useConstant(() => cleanProps(blocks, media))
	const navigate = useNavigate()
	const location = useLocation()

	const PER_PAGE = 8

	const [showCategories, setShowCategories] = useState(true)
	const [showTopics, setShowTopics] = useState(true)
	const [showDates, setShowDates] = useState(true)
    const [results, setResults] = useState([])
    const [params, setParams] = useState({ cats: [], dates: [], topics: [] })

    const posts = useConstant(() => formatPosts(entries, JSON.parse(media)))
	
	const ids = featuredPosts.map(post => post.wordpress_id)
	const featured = entries
        .filter(post => ids.includes(post.wordpress_id))
        .map(post => ({
            title: post.title,
            cta_text: 'Read More',
            cta_link: post.path,
            image: post.acf.slider_image,
            mobile_image: post.acf.thumbnail_image
        }))
						
	const dates = getYearsArray(2018)
	const intro = data.find(item => item.type === 'acf/one-coloumn-block')
	const formData = data.find(item => item.type === 'acf/form-block')

	const getParam = (name) => parse(location.search)[name] ? parse(location.search)[name].split(",") : []
	const getPage = () => parse(location.search).page || 1

    // Set initial results
    useEffect(() => {
        const ps = { cats: getParam('cats'), dates: getParam('dates'), topics: getParam('topics') }
        setResults(filterPosts(posts, ps))
        setParams(ps)
    }, [])
		
	const handleParamsChange = (name, isChecked, value) => {
        const ps = {
            ...params, 
            [name]: isChecked ? [...params[name], value] : params[name].filter(p => p !== value)
        }
        setResults(filterPosts(posts, ps))
        setParams(ps)

		const query = Object.entries(ps).reduce((result, [key, array]) => {
			return `${result}${key}=${array.join(",")}&`
		}, "")

		navigate(`?${query}page=1#filters`)
	}

	const handlePageChange = (increment) => {
		const page = getPage()
		
		const query = Object.entries(params).reduce((result, [key, array]) => {
			return `${result}${key}=${array.join(",")}&`
		}, "")
			
		navigate(`?${query}page=${parseInt(page) + increment}#filters`)
	}

	const start = (getPage() - 1) * PER_PAGE
	const isPrev = getPage() > 1
	const isNext = start + PER_PAGE < results.length

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<section className="wrapper">
				<SimpleSlider slides={featured} />
			</section>

			{intro && (
				<div id="_row_1_" className="py-20">
					<OneColumnBlock {...intro} />
				</div>
			)}

			<div
				id={intro ? '_row_2_' : '_row_1_'}
				className="mb-20 mt-16 wrapper md:mb-24 lg:mb-32"
			>
				<div id="filters" className="block md:flex justify-between">
					<aside className="w-full md:w-1/4 lg:w-1/6 mb-8">
						<h3 className="font-sm font-museoSansBold mb-4">Filter By</h3>
						<div className="mb-4">
							<header className="flex justify-between">
								<h4 className="font-museoSansRegular mb-2">Category</h4>
								<IconWrapper icon={DownArrow} wrapperClassName="w-5" onClick={() => setShowCategories(!showCategories)}/>
							</header>
							<div
								className={classNames(
									'overflow-hidden',
									showCategories ? 'h-full' : 'h-0'
								)}
							>
								{ categories.map(({ title, slug }) => {
									return (
										<label className="block mb-2" key={slug}>
											<input 
												onChange={(e) => handleParamsChange('cats', e.target.checked, slug)}
												className={`mr-4 ${styles.checkbox}`} 
												type="checkbox" 
												name={title} 
												checked={params.cats.includes(slug)}
											/>{title}
										</label>
									)
								})}
							</div>
						</div>
						{/* To go live soon... */}
						{/* <div className="mb-4">
							<header className="flex justify-between">
								<h4 className="font-museoSansRegular mb-2">Topics</h4>
								<IconWrapper icon={DownArrow} wrapperClassName="w-5" onClick={() => setShowTopics(!showTopics)}/>
							</header>
							<div
								className={classNames(
									'overflow-hidden',
									showTopics ? 'h-full' : 'h-0'
								)}
							>
								{ topics.map(({ wordpress_id: id, name }) => (
									<label className="block mb-2" key={id}>
										<input 
											onChange={(e) => handleParamsChange('topics', e.target.checked, id.toString())}
											className={classNames(
												styles.checkbox,
												getParam('topics').includes(id.toString()) && styles.checked,
												"mr-4"
											)} 
											type="checkbox" 
											name={name} 
											checked={getParam('topics').includes(id.toString())}
										/>{name}
									</label>
								))}
							</div>
						</div> */}
						<div className="mb-4">
							<header className="flex justify-between">
							<h4 className="font-museoSansRegular mb-2">Date</h4>
								<IconWrapper icon={DownArrow} wrapperClassName="w-5" onClick={() => setShowDates(!showDates)}/>
							</header>
							<div
								className={classNames(
									'overflow-hidden',
									showDates ? 'h-full' : 'h-0'
								)}
							>
								{ dates.map((date) => (
									<label className="block mb-2" key={date}>
										<input 
											onChange={(e) => handleParamsChange('dates', e.target.checked, date.toString())}
											className={classNames(
												styles.checkbox,
												getParam('dates').includes(date.toString()) && styles.checked,
												"mr-4"
											)} 
											type="checkbox" 
											name={date} 
											checked={params.dates.includes(date.toString())}
										/>{date}
									</label>
								))}
							</div>
						</div>
					</aside>
					<div className="mb-8 md:mb-12 lg:mb-16 w-full md:w-3/4 lg:w-5/6 md:-ml-4 lg:-ml-8">
						<h1 className="md:pl-4 lg:pl-8 text-h3-fluid w-11/12 mx-auto sm:w-full mb-6 lg:mb-10 font-museoSans leading-tight text-darkgrey">
							The latest news, blogs, guides, and research from the Ultraleap team
						</h1>
						<div className="md:flex md:flex-wrap">
							{
								!results.length 
									? <p>Sorry, nothing matches your search terms...</p>
									: results.slice(start, start + PER_PAGE).map(
									({ title, wordpress_id, path, image, category, modified }) => (
										<NewsThumb
											className="mb-4 lg:mb-8 xl:w-1/4"
											title={title}
											path={path}
											image={image}
											label={category}
											key={wordpress_id}
											id={wordpress_id}
											modified={modified}
											media={media}
										/>
									))
							}
						</div>
					</div>
				</div>
				<div className="flex justify-center text-darkgrey">
					{isPrev && (
						<Button
							theme="white"
							className="mx-2 border-2 border-primary text-primary"
							onClick={() => handlePageChange(-1)}
						>
							Previous
						</Button>
					)}
					{isNext && (
						<Button
							theme="white"
							className="mx-2 border-2 border-primary text-primary"
							onClick={() => handlePageChange(1)}
						>
							Next
						</Button>
					)}
				</div>
			</div>
			<div className="mb-0 content-block md:mb-8 lg:mb-12 md:wrapper">
				<TextImageCtaBlock 
					image_text_cta_background_colour="purple"
					image_text_cta_background_gradient={true}
					image_text_cta_cta_background_colour="orange"
					image_text_cta_cta_link={{title: 'Sign me up', url: '/newsletter-sign-up/'}}
					image_text_cta_cta_text_colour="light"
					image_text_cta_cta_type="solid"
					image_text_cta_text="Our regular newsletter keeps our community up to date with the latest developments in human-computer interaction across XR, automotive, and touchless interfaces."
					image_text_cta_text_colour="light"
					image_text_cta_title="Interspatial newsletter"
					size="wide"
					image_text_cta_image={{source_url: 'https://cms.ultraleap.com/app/uploads/2021/11/northern-lights.jpg'}}
				/>
			</div>
			{formData && <ContactForm {...formData} />}
		</>
	)
}

News.propTypes = {
	// pageContext: T.news.isRequired
}

export const query = graphql`
	query NewsQuery {
		featuredPosts: wordpressAcfOptions {
			options {
				featured_posts {
					wordpress_id
				}
			}
		}
	}
`

export default News
