/* eslint-disable react/prop-types */
import React from 'react'
import { CaseStudyCard } from '@/components/CaseStudyCard'

function NewsThumb({ title, path, image, label, id }) {
	return (
		<CaseStudyCard
			className="mb-4 lg:mb-8 xl:w-1/4"
			title={title}
			path={path}
			image={image}
			id={id}
			label={label}
		/>
	)
}

export default NewsThumb
