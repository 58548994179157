import { useStaticQuery, graphql } from 'gatsby'
// eslint-disable-next-line no-unused-vars
import { postQuery } from '@/queries'

function useNews() {
	const data = useStaticQuery(graphql`
		{
			page: wordpressPage(path: { eq: "/company/news/" }) {
				...pageQuery
			}
		}
	`)

	return data.page
}

export default useNews
